.plans-container {
    /* margin-top: 4rem; */
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    width: 15rem;
    padding: 1.5rem;
}

.plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}

.plan > svg {
    fill: var(--orange)
}

.plan>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5) {
    font-size: 0.8rem;
   
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature > img {
    width: 1rem;
}

.plans>:nth-child(2)>svg {
    fill: white;
}

.plans>:nth-child(2)>button {
    color: orange;
}

.plans-blur-1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

@media screen and (max-width: 768px) {
    .plans {
        flex-direction: column;
    }
    .plans > :nth-child(2) {
        transform: none;
    }
}